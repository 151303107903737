import { Box, Typography } from "@mui/material";

const iframeStyles: React.CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: "100%",
  height: "100%",
  border: "none",
  margin: 0,
  padding: 0,
  overflow: "hidden",
  zIndex: 999999,
};

function Shop() {
  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Typography variant="h1" component="h1">
        Shop
      </Typography>

      <Box position="relative" mt={2} flex={1}>
        <Box position="absolute" component="iframe" style={iframeStyles} src="https://www.staging25.spiritshack.co.uk/shop.html" />
      </Box>
    </Box>
  );
}

export default Shop;
