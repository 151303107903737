import { create } from "zustand";

interface Auth {
  authenticated?: boolean;
  setAuth: (status: boolean) => void;
}

const useAuthStore = create<Auth>(set => ({
  authenticated: undefined,
  setAuth: (status: boolean) => {
    set({ authenticated: status });
  },
}));

export default useAuthStore;
