export const base = "Dashboard";
export const users = "Users";
export const profile = "Profile";
export const editProfile = "Edit Profile";
export const changePassword = "Change Password";
export const live = "Live";
export const history = "History";
export const sensors = "Sensors";
export const shop = "Shop";
export const setting = "Setting";
