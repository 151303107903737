import { AppBar, Toolbar, IconButton, Typography, useTheme, Divider, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useTitleStore from "../../store/titleStore";
import ProfileMenu from "../profile-menu";

interface Props {
  drawerWidth: number;
  handleDrawerToggle: () => void;
}

function Header({ drawerWidth, handleDrawerToggle }: Props) {
  const { title } = useTitleStore();
  const theme = useTheme();

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        background: theme.palette.common.white,
      }}
      elevation={0}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <IconButton color="inherit" aria-label="open drawer" edge="start" sx={{ mr: 2, display: { sm: "none" } }} onClick={handleDrawerToggle}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" color={theme.palette.primary.dark}>
          {title}
        </Typography>

        <Box display="flex" alignItems="center">
          <ProfileMenu />
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
}

export default Header;
