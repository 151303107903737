import { Box, styled } from "@mui/material";
import { DataGrid, type GridColDef, type GridValidRowModel, type DataGridProps } from "@mui/x-data-grid";

const MuiTable = styled(DataGrid)`
  & .MuiDataGrid-root {
    background-color: #fcfdfd !important;
  }
  & .MuiDataGrid-columnHeaderTitle {
    font-weight: 600 !important;
    font-size: 14px !important;
  }
  & .MuiDataGrid-cellContent {
    font-size: 14px !important;
  }
  .MuiDataGrid-virtualScrollerContent {
    max-height: calc(64px * 10);
  }
`;

interface Props {
  rows?: readonly GridValidRowModel[] | undefined;
  columns: Array<GridColDef<GridValidRowModel>>;
  tableProps?: Partial<DataGridProps>;
}

function Table({ rows, columns, tableProps }: Props) {
  return (
    <Box>
      <MuiTable
        rows={rows}
        columns={columns}
        columnHeaderHeight={54}
        rowHeight={64}
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        {...tableProps}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
            ...tableProps?.initialState?.pagination,
          },
          ...tableProps?.initialState,
        }}
        sx={{
          backgroundColor: "#ffff",
          height: rows?.length === 0 ? "200px" : "auto",
          ...tableProps?.sx,
        }}
      />
    </Box>
  );
}

export default Table;
