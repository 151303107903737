import { useEffect, useMemo, useState } from "react";
import Login from "./login";
import Register from "./register";
import { useLocation, useNavigate } from "react-router-dom";
import ForgotPassword from "./forgot-password";
import { Box, Paper, Typography } from "@mui/material";
import Otp from "./otp";
import ResetPassword from "./reset-password";
import { capitalize } from "lodash";

export type AuthPage = "login" | "register" | "forgot-password" | "reset-password" | "otp";
export type AuthAction = "verify" | "forgot";

export type AuthNavigate =
  | {
      page: "login" | "register" | "forgot-password";
    }
  | {
      page: "otp";
      action: "verify";
      transient_key: string;
    }
  | {
      page: "otp";
      action: "forgot";
      email: string;
    }
  | {
      page: "reset-password";
      email: string;
      reset_token: string;
    };

export interface AuthProps {
  navigateToAuth: (pages: AuthNavigate) => void;
  actionQuery?: AuthAction;
}

function Authorization() {
  const location = useLocation();
  const navigate = useNavigate();
  const search = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const pageQuery = (search.get("page") as AuthPage) || "login";
  const actionQuery = search.get("action") as AuthAction;
  const [page, setPage] = useState<AuthPage>(pageQuery);

  useEffect(() => {
    setPage(pageQuery || "login");
  }, [pageQuery]);

  const navigateToAuth = (pages: AuthNavigate) => {
    const searchQuery = Object.entries(pages)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join("&");
    navigate({ search: searchQuery });
  };

  return (
    <Box p={6}>
      <Paper variant="outlined" sx={{ p: 3, maxWidth: 500, mx: "auto" }}>
        <Typography component="h1" variant="h4" fontWeight={700} mb={2}>
          {(pageQuery || "")
            .split("-")
            .map(val => capitalize(val))
            .join(" ")}
        </Typography>
        {page === "login" && <Login navigateToAuth={navigateToAuth} />}
        {page === "register" && <Register navigateToAuth={navigateToAuth} />}
        {page === "forgot-password" && <ForgotPassword navigateToAuth={navigateToAuth} />}
        {page === "otp" && <Otp actionQuery={actionQuery} navigateToAuth={navigateToAuth} />}
        {page === "reset-password" && <ResetPassword actionQuery={actionQuery} navigateToAuth={navigateToAuth} />}
      </Paper>
    </Box>
  );
}

export default Authorization;
