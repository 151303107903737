import { create } from "zustand";

interface TitleStore {
  title: string;
  setTitle: (title: string) => void;
}

const useTitleStore = create<TitleStore>(set => ({
  title: "",
  setTitle: (title: string) => {
    set({ title });
  },
}));

export default useTitleStore;
