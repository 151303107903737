import Paper from "@mui/material/Paper/Paper";
import useUserStore from "../../store/userStore";
import { Typography } from "@mui/material";

function Dashboard() {
  const { user } = useUserStore();

  const recentlyLoggedIn = new Date(user?.registered_date ?? "").getTime() > new Date().getTime() - 5 * 60 * 1000;

  return (
    <Paper variant="outlined" sx={{ p: 5 }}>
      <Typography component="p" variant="body1"></Typography>
      Hello{" "}
      <Typography component="span" color="primary" fontWeight={600}>
        {user?.name}
      </Typography>
      {". "}
      Welcome {!recentlyLoggedIn && "back"} to HexCloud
    </Paper>
  );
}

export default Dashboard;
