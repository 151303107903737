import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";

import { ColorModeContext, useMode } from "../theme";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";

import Router from "./router";
import API_CALL from "../services";
import useUserStore from "../store/userStore";
import useAuthStore from "../store/authStore";
import { BrowserRouter } from "react-router-dom";
import Authorization from "../pages/authorization";
// import Authentication from "../components/auth";

function App(): JSX.Element {
  const [theme, colorMode] = useMode();
  const { setUser } = useUserStore();
  const { authenticated, setAuth } = useAuthStore();

  const isLoggedIn = async () => {
    try {
      const { data: res } = await API_CALL.validate();
      setUser(res.data);

      setAuth(true);
    } catch (error: unknown) {
      setAuth(false);
    }
  };

  useEffect(() => {
    void isLoggedIn();
  }, []);

  useEffect(() => {
    document.body.setAttribute("data-theme", theme.palette.mode);
  }, [theme.palette.mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer />
        {authenticated === false && (
          <BrowserRouter>
            <Authorization />
          </BrowserRouter>
        )}

        {authenticated && <Router />}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
