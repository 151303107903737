import { type PaletteMode } from "@mui/material";
import { type LinkProps } from "@mui/material/Link";
import { createTheme, type Theme, type ThemeOptions } from "@mui/material/styles";
import { createContext, useState, useMemo } from "react";

import LinkBehavior from "./utils/linkBehaviour";

export const tokens = (mode: PaletteMode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
      }
    : {
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
      }),
});

export const themeSettings = (): ThemeOptions => ({
  palette: {
    mode: "light",
    primary: {
      main: "#128200",
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    h1: {
      fontSize: 28,
      fontWeight: 500,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } satisfies LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
});

export const ColorModeContext = createContext({
  toggleColorMode: () => {
    //
  },
});

type UseModeType = [Theme, { toggleColorMode: () => void }];

export const useMode = (): UseModeType => {
  const savedTheme = window.localStorage.getItem("theme") as PaletteMode | null;
  const [, setMode] = useState<PaletteMode>(savedTheme ?? "light");

  const theme = useMemo(() => createTheme(themeSettings()), []);

  (window as any).theme = theme; // eslint-disable-line @typescript-eslint/no-explicit-any

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode(prev => {
          const themeMode = prev === "light" ? "dark" : "light";

          return themeMode;
        });
      },
    }),
    [],
  );

  return [theme, colorMode];
};
