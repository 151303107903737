import { create } from "zustand";
import { type User } from "../types/auth";
interface UserStore {
  user: User | null;
  setUser: (user: User) => void;
  clearUser: () => void;
}

const useUserStore = create<UserStore>(set => ({
  user: null,
  setUser: (user: User) => {
    set({ user });
  },
  clearUser: () => {
    set({ user: null });
  },
}));

export default useUserStore;
