import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from "@mui/material";
import * as path from "../../router/path";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { dashboardRoutes } from "../../router";
import { capitalize } from "lodash";

const checkForRedirect = (pathname: string, routes: typeof dashboardRoutes): boolean => {
  for (const route of routes) {
    const fullPath = route.index ? path.base : route.path;

    if (fullPath === pathname) {
      return !!route.redirect;
    }

    if (route.children) {
      const childTitle = checkForRedirect(pathname, route.children);
      if (childTitle) {
        return childTitle;
      }
    }
  }
  return false;
};

const findRouteTitle = (pathname: string, routes: typeof dashboardRoutes): string => {
  for (const route of routes) {
    const fullPath = route.index ? path.base : route.path;

    if (fullPath === pathname) {
      return route.title;
    }

    if (route.children) {
      const childTitle = findRouteTitle(pathname, route.children);
      if (childTitle) {
        return childTitle;
      }
    }
  }
  return "";
};

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter(x => x);

  return (
    <MuiBreadcrumbs aria-label="breadcrumb">
      <Link sx={{ textDecoration: "none" }} component={RouterLink} to={path.base}>
        {findRouteTitle(path.base, dashboardRoutes)}
      </Link>
      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const name = findRouteTitle(to, dashboardRoutes);
        const redirect = checkForRedirect(to, dashboardRoutes);
        const last = index === pathnames.length - 1;

        return last || redirect ? (
          <Typography color="text.primary" key={to}>
            {name || capitalize(value.replace(/-/g, " "))}
          </Typography>
        ) : (
          <Link sx={{ textDecoration: "none" }} component={RouterLink} to={to} key={to}>
            {name || capitalize(value.replace(/-/g, " "))}
          </Link>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
