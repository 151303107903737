import { Fragment, useState } from "react";
import { TextField, Button, Box, Typography, Alert } from "@mui/material";
import API_CALL from "../../services";
import * as yup from "yup";
import { type AuthProps } from ".";
import { useFormik } from "formik";
import { type ForgotPasswordRequestBody } from "../../types/auth";
import { capitalize } from "lodash";

type InputArr = keyof ForgotPasswordRequestBody;

const validationSchema = yup.object<ForgotPasswordRequestBody>({
  email: yup.string().required("Email is required").email("Please enter a valid email address"),
});

const ForgotPassword = ({ navigateToAuth }: AuthProps): JSX.Element => {
  const [error, setError] = useState("");
  const formik = useFormik<ForgotPasswordRequestBody>({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async values => {
      await submit(values);
    },
    onReset: () => {},
  });

  const submit = async (credentials: ForgotPasswordRequestBody) => {
    try {
      const { data: res } = await API_CALL.forgotPassword(credentials);
      console.log("OTP", res.data.otp);
      navigateToAuth({ page: "otp", action: "forgot", email: credentials.email });
    } catch (err: any) {
      const msg = err.response.data.message;
      setError(String(msg));
    }
  };

  return (
    <Fragment>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "grid", gap: 2 }}>
          {(Object.keys(formik.values) as InputArr[]).map((key, idx) => (
            <TextField
              key={idx}
              name={key}
              type={"email"}
              label={capitalize(key)}
              value={formik.values[key]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[key] && Boolean(formik.errors[key])}
              helperText={formik.touched[key] && formik.errors[key]}
            />
          ))}

          {error && <Alert severity="error">{error}</Alert>}

          <Button type="submit" variant="contained" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? "Sending Email" : "Send Email"}
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          mt: 6,
          mb: -2,
          p: 2,
          textAlign: "center",
          width: "100%",
        }}>
        <Typography sx={{ fontSize: "14px" }}>
          Remember your password?{" "}
          <Button
            onClick={() => {
              navigateToAuth({ page: "login" });
            }}
            sx={{
              fontSize: "14px",
              textTransform: "none",
              "&:hover": {
                textDecoration: "underline",
                backgroundColor: "inherit", // To prevent background change on hover
              },
            }}>
            Login
          </Button>
        </Typography>
      </Box>
    </Fragment>
  );
};

export default ForgotPassword;
