import { Alert, Box, Button, TextField } from "@mui/material";
import * as yup from "yup";
import { type UpdatePasswordRequestBody } from "../../types/auth";
import { useFormik } from "formik";
import API_CALL from "../../services";
import notify from "../../utils/notify";
import { useState } from "react";
import { capitalize } from "lodash";

type InputArr = keyof UpdatePasswordRequestBody;

const validationSchema = yup.object<UpdatePasswordRequestBody>({
  old_password: yup.string(),
  new_password: yup.string().min(8, "Password should be of minimum 8 characters length"),
  confirm_password: yup.string().oneOf([yup.ref("new_password")], "Passwords must match"),
});

export default function ChangePassword() {
  const [error, setError] = useState("");
  const formik = useFormik<UpdatePasswordRequestBody>({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema,
    onSubmit: async values => {
      await submit(values);
    },
    onReset: () => {},
  });

  const submit = async (credentials: UpdatePasswordRequestBody) => {
    try {
      await API_CALL.updatePassword(credentials);
      notify("success", "Your password was updated");
    } catch (err: any) {
      const msg = err.response.data.message;
      setError(String(msg));
    }
  };
  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Box sx={{ display: "grid", gap: 2 }}>
        {(Object.keys(formik.values) as InputArr[]).map((key, idx) => (
          <TextField
            key={idx}
            name={key}
            type="password"
            label={capitalize(key.split("_").join(" "))}
            value={formik.values[key]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[key] && Boolean(formik.errors[key])}
            helperText={formik.touched[key] && formik.errors[key]}
          />
        ))}

        {error && <Alert severity="error">{error}</Alert>}

        <Button type="submit" variant="contained" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? "Updating" : "Update"}
        </Button>
      </Box>
    </Box>
  );
}
