import { type ReactNode, useEffect } from "react";
import useTitleStore from "../../store/titleStore";

interface Props {
  title: string;
  children: ReactNode;
}

const TitleSetter = ({ title, children }: Props) => {
  const setTitle = useTitleStore(state => state.setTitle);

  useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);

  return children;
};

export default TitleSetter;
