import Box from "@mui/material/Box";
import { type GridColDef } from "@mui/x-data-grid";
import Table from "../../components/table";
import Tabs from "../../components/tabs";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import API_CALL from "../../services";
import { Button, Chip, IconButton, InputAdornment, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Input from "../../components/input";
import downloadCSV from "../../utils/downloadCsv";
import notify from "../../utils/notify";
import useUsersStore, { type UserPagination } from "../../store/usersStore";
import { sumBy, values } from "lodash";

export default function Users() {
  const { userNavigation, pushUsers, setUserNavigationType, setUserNavigation, ...userStore } = useUsersStore();
  const { active, inactive } = userStore;
  const {
    type: userType,
    pagination: {
      [userType]: { current_page: currentPage },
    },
  } = userNavigation;

  const users = userStore[userNavigation.type][currentPage];
  const activUsersFetched = sumBy(values(active), arr => arr.length);
  const inactiveUsersFetched = sumBy(values(inactive), arr => arr.length);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const getUsers = async () => {
    if (users?.length > 0) {
      return;
    }
    try {
      setLoading(true);
      const { data: res } = await API_CALL.getUsers(userNavigation.type, currentPage);
      setUserNavigation(userNavigation.type, res.data.pagination);
      pushUsers(res.data.users, userNavigation.type, res.data.pagination.current_page);
    } catch (e: any) {
      notify("error", String(e.response.data.message));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setUserNavigationType("active");
  }, []);

  useEffect(() => {
    void getUsers();
  }, [currentPage, userNavigation.type]);

  const updatePagination = (idx: number) => {
    const type = idx === 0 ? "active" : "inactive";
    setUserNavigationType(type);
    setUserNavigation(idx === 0 ? "active" : "inactive", {
      ...userNavigation.pagination[type],
      current_page: 1,
    });
  };

  const filteredUsers = (users ?? []).filter(val => {
    const searchByName = val.name?.toLowerCase().includes(search.toLowerCase());
    const searchByEmail = val.email?.toLowerCase().includes(search.toLowerCase());
    return searchByName || searchByEmail;
  });

  const onPageChange = (dir: "prev" | "next") => {
    let tempCurrentPage;
    if (dir === "prev") {
      tempCurrentPage = currentPage - 1;
    } else {
      tempCurrentPage = currentPage + 1;
    }

    setUserNavigation(userNavigation.type, {
      ...userNavigation.pagination[userNavigation.type],
      current_page: tempCurrentPage,
    });
  };

  const tabLabels = [
    { label: "Active Users", users: activUsersFetched },
    { label: "Inactive Users", users: inactiveUsersFetched },
  ];

  return (
    <Box>
      <Box>
        <Tabs
          title="User Tabs"
          tabLabels={tabLabels.map((val, idx) => (
            <Box key={idx} display="flex" alignItems="center" gap={1}>
              {val.label} <Chip label={val.users} size="small" />
            </Box>
          ))}
          onClick={updatePagination}>
          {[0, 1].map(val => (
            <Box key={val} position="relative">
              <TableHeader search={search} setSearch={setSearch} filename="active-users.csv" data={filteredUsers} />
              <Table rows={filteredUsers} columns={columns} tableProps={{ loading, hideFooterPagination: true }} />
              <TableFooter
                pagination={userNavigation.pagination[userType]}
                loading={loading}
                onPrev={() => {
                  onPageChange("prev");
                }}
                onNext={() => {
                  onPageChange("next");
                }}
              />
            </Box>
          ))}
        </Tabs>
      </Box>
    </Box>
  );
}

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "S.no",
    width: 70,
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    headerAlign: "left",
    align: "left",
    flex: 1,
  },
  {
    field: "email",
    headerName: "Email",
    sortable: true,
    headerAlign: "left",
    align: "left",
    flex: 1,
  },
  {
    field: "consent_given",
    headerName: "Consent",
    headerAlign: "left",
    align: "left",
    width: 200,
    renderCell(params) {
      const isGiven = params.row.consent_given === "1";
      return <Chip label={isGiven ? "Agreed" : "Not Agreed"} variant="outlined" color={isGiven ? "success" : "error"} />;
    },
  },
  {
    field: "web_logged_in_at",
    headerName: "Logged in via web",
    sortable: false,
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell(cell) {
      return cell.row.web_logged_in_at ? new Date(String(cell.row.web_logged_in_at)).toLocaleString() : "—";
    },
  },
  {
    field: "mobile_logged_in_at",
    headerName: "Logged in via mobile",
    sortable: false,
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell(cell) {
      return cell.row.mobile_logged_in_at ? new Date(String(cell.row.mobile_logged_in_at)).toLocaleString() : "—";
    },
  },
];

interface TableHeaderProps {
  search: string;
  data: any[];
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  filename: string;
}

const header: Array<Record<string, any>> = [
  { title: "Name", target: "name" },
  { title: "Email", target: "email" },
  { title: "Consent given", target: "consent_given" },
  { title: "Logged in via web", target: "web_logged_in_at" },
  { title: "Logged in via mobile", target: "mobile_logged_in_at" },
];

function TableHeader({ search, setSearch, filename, data }: TableHeaderProps) {
  return (
    <Box
      sx={{
        width: "100%",
        mb: 2,
        display: "flex",
        justifyContent: "space-between",
      }}>
      <Input
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "#ffff",
            borderRadius: "100px",
            width: "400px",
          },
        }}
        fullWidth
        placeholder="Search"
        value={search}
        onChange={e => {
          setSearch(e.target.value);
        }}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          },
        }}
      />
      <Button
        title="Export to CSV"
        variant="contained"
        sx={{ whiteSpace: "nowrap", px: 4 }}
        onClick={() => {
          if (data?.length > 0) {
            downloadCSV(filename, header, data);
          } else {
            notify("warning", "There is no data to be exported");
          }
        }}>
        Export to CSV
      </Button>
    </Box>
  );
}
interface TableFooterProps {
  pagination: UserPagination;
  loading: boolean;
  onPrev: () => void;
  onNext: () => void;
}

function TableFooter({ pagination, loading, onPrev, onNext }: TableFooterProps) {
  const { per_page: usersPerPage, current_page: currentPage, total_pages: totalPages, total_users: totalUsers } = pagination;
  const start = (currentPage - 1) * usersPerPage + 1;
  const end = Math.min(currentPage * usersPerPage, totalUsers);
  const total = totalUsers ?? 0;
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  return (
    <Box paddingInline={2} display="flex" alignItems="center" justifyContent="flex-end" height={53} position="absolute" bottom={0} left={0} width="100%">
      <Typography variant="body2" component="p" mr="auto">
        Page {currentPage}
      </Typography>

      <Typography variant="body2" component="p" mr={2}>
        {!loading && `${start}-${end} of ${total}`}
      </Typography>

      <IconButton disabled={loading || isFirstPage} onClick={onPrev}>
        <ChevronLeftIcon />
      </IconButton>

      <IconButton disabled={loading || isLastPage} onClick={onNext}>
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
}
