import { Paper, ToggleButton, ToggleButtonGroup } from "@mui/material";
import * as path from "../../router/path";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import * as title from "../../router/title";
import PasswordIcon from "@mui/icons-material/Password";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useState } from "react";

export default function Profile() {
  const location = useLocation();
  const navigate = useNavigate();
  const [alignment, setAlignment] = useState<string>(location.pathname);

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: keyof typeof title) => {
    setAlignment(newAlignment);
    navigate(newAlignment);
  };

  return (
    <Paper variant="outlined" sx={{ p: 5 }}>
      <ToggleButtonGroup sx={{ mb: 3 }} value={alignment} exclusive onChange={handleAlignment} aria-label="text alignment">
        <ToggleButton sx={{ display: "flex", alignItems: "center", gap: 2 }} value={path.editProfile} aria-label={path.editProfile}>
          <ManageAccountsIcon /> {title.editProfile}
        </ToggleButton>
        <ToggleButton sx={{ display: "flex", alignItems: "center", gap: 2 }} value={path.changePassword} aria-label={path.changePassword}>
          <PasswordIcon /> {title.changePassword}
        </ToggleButton>
      </ToggleButtonGroup>

      {location.pathname === path.profile ? <Navigate to={path.editProfile} replace /> : <Outlet />}
    </Paper>
  );
}
