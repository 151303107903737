const convertJsonToString = (header: Array<Record<string, any>>, data: any[]) => {
  const head = header.map(val => val.title).join(",");
  const body = data.map(row => {
    return header.map(val => row[val.target]).join(",");
  });

  return [head, body.join("\n")].join("\n");
};

const downloadCSV = (fileName: string, header: Array<Record<string, any>>, data: any[]) => {
  const jsonData = convertJsonToString(header, data);
  const csvData = new Blob([jsonData], { type: "text/csv" });
  const csvURL = URL.createObjectURL(csvData);
  const link = document.createElement("a");
  link.href = csvURL;
  link.download = `${fileName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadCSV;
