import { Fragment, useState } from "react";
import { TextField, Button, Box, Typography, Alert } from "@mui/material";
import API_CALL from "../../services";
import * as yup from "yup";
import { type AuthProps } from ".";
import { useFormik } from "formik";
import { type RegisterRequestBody } from "../../types/auth";
import { capitalize } from "lodash";

type InputArr = keyof RegisterRequestBody;

const validationSchema = yup.object<RegisterRequestBody>({
  email: yup.string().required("Email is required").email("Please enter a valid email address"),
  username: yup.string().required("Username is required"),
  password: yup.string().min(8, "Password should be of minimum 8 characters length").required("Password is required"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
});

const Register = ({ navigateToAuth }: AuthProps): JSX.Element => {
  const [error, setError] = useState("");
  const formik = useFormik<RegisterRequestBody>({
    initialValues: {
      email: "",
      username: "",
      password: "",
      confirm_password: "",
    },
    validationSchema,
    onSubmit: async values => {
      await submit(values);
    },
    onReset: () => {},
  });

  const submit = async (credentials: RegisterRequestBody) => {
    try {
      const { data: res } = await API_CALL.register(credentials);
      console.log("OTP", res.data.data.otp);
      navigateToAuth({ page: "otp", action: "verify", transient_key: res.data.data.transient_key });
    } catch (err: any) {
      const msg = err.response.data.message;
      setError(String(msg));
    }
  };

  return (
    <Fragment>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "grid", gap: 2 }}>
          {(Object.keys(formik.values) as InputArr[]).map((key, idx) => (
            <TextField
              key={idx}
              name={key}
              type={key === "password" || key === "confirm_password" ? "password" : key === "email" ? "email" : "text"}
              label={capitalize(key.split("_").join(" "))}
              value={formik.values[key]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[key] && Boolean(formik.errors[key])}
              helperText={formik.touched[key] && formik.errors[key]}
            />
          ))}

          {error && <Alert severity="error">{error}</Alert>}

          <Button type="submit" variant="contained" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? "Registering" : "Register"}
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          mt: 6,
          mb: -2,
          p: 2,
          textAlign: "center",
          width: "100%",
        }}>
        <Typography sx={{ fontSize: "14px" }}>
          Already have an account?{" "}
          <Button
            onClick={() => {
              navigateToAuth({ page: "login" });
            }}
            sx={{
              fontSize: "14px",
              textTransform: "none",
              "&:hover": {
                textDecoration: "underline",
                backgroundColor: "inherit", // To prevent background change on hover
              },
            }}>
            Login
          </Button>
        </Typography>
      </Box>
    </Fragment>
  );
};

export default Register;
