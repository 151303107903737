import { Navigate, Outlet, type RouteObject } from "react-router-dom";
import * as path from "./path";
import * as title from "./title";

import Dashboard from "../pages/dashboard";
import Users from "../pages/users";
import Layout from "../components/layout";
import TitleSetter from "../components/title-setter";
import EditProfile from "../pages/edit-profile";
import ChangePassword from "../pages/change-password";
import Profile from "../pages/profile";
import useUserStore from "../store/userStore";
import { Role } from "../types/enum";
import Live from "../pages/live";
import History from "../pages/history";
import Sensors from "../pages/sensors";
import Setting from "../pages/setting";
import Shop from "../pages/shop";

interface DashboardURL {
  index?: boolean;
  title: string;
  element: JSX.Element;
  path?: string;
  authorized?: boolean;
  redirect?: boolean;
  children?: DashboardURL[];
}

export const dashboardRoutes: DashboardURL[] = [
  {
    index: true,
    title: title.base,
    element: <Dashboard />,
  },
  {
    path: path.users,
    title: title.users,
    element: <Users />,
    authorized: true,
  },
  {
    path: path.live,
    title: title.live,
    element: <Live />,
  },
  {
    path: path.history,
    title: title.history,
    element: <History />,
  },
  {
    path: path.sensors,
    title: title.sensors,
    element: <Sensors />,
  },
  {
    path: path.shop,
    title: title.shop,
    element: <Shop />,
  },
  {
    path: path.setting,
    title: title.setting,
    element: <Setting />,
  },
  {
    path: path.profile,
    element: <Profile />,
    redirect: true,
    title: title.profile,
    children: [
      {
        path: path.editProfile,
        title: title.editProfile,
        element: <EditProfile />,
      },
      {
        path: path.changePassword,
        title: title.changePassword,
        element: <ChangePassword />,
      },
    ],
  },
];

interface ProtectedRouteProps {
  authorized?: boolean;
  children: React.ReactNode;
}

function ProtectedRoutes({ authorized, children }: ProtectedRouteProps) {
  const { user } = useUserStore();
  const isAdmin = user?.role === Role.AMIN;

  return authorized ? isAdmin ? children : <Navigate to={path.base} replace /> : children;
}

const createRoutesWithTitles = (routes: DashboardURL[]): any[] =>
  routes.map(route => {
    const RouteElement = <TitleSetter title={String(route.title)}>{route.element}</TitleSetter>;

    return {
      path: route.path,
      index: route.index,
      element: <ProtectedRoutes authorized={route.authorized}>{RouteElement}</ProtectedRoutes>,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      children: route.children ? createRoutesWithTitles(route.children) : null,
    };
  });

const router: RouteObject[] = [
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        element: <Layout />,
        children: createRoutesWithTitles(dashboardRoutes),
      },
    ],
  },
];

export default router;
