import { forwardRef } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, type LinkProps as RouterLinkProps } from "react-router-dom";

const LinkBehavior = forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }>((props, ref) => {
  const { href, ...other } = props;
  return <RouterLink ref={ref} to={href} {...other} />;
});

LinkBehavior.displayName = "LinkBehavior";

// Add prop types for validation
LinkBehavior.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object, // If using objects for routes
  ]).isRequired,
};

export default LinkBehavior;
