export const base = "/";
export const users = "/users";
export const profile = "/profile";
export const editProfile = profile + "/edit-profile";
export const changePassword = profile + "/change-password";
export const live = "/live";
export const history = "/history";
export const sensors = "/sensors";
export const shop = "/shop";
export const setting = "/setting";
