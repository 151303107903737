import { Fragment, useMemo, useState } from "react";
import { TextField, Button, Box, Alert } from "@mui/material";
import API_CALL from "../../services";
import * as yup from "yup";
import { type AuthProps } from ".";
import { useFormik } from "formik";
import { type VerifyOtpRequestBody } from "../../types/auth";
import { capitalize } from "lodash";
import { useLocation } from "react-router-dom";
import notify from "../../utils/notify";

interface VerifyOtpInputData {
  otp: string | number;
}
type VerifyOtpInput = Omit<VerifyOtpRequestBody, "transient_key">;
type InputArr = keyof VerifyOtpInput;

const validationSchema = yup.object<VerifyOtpInput>({
  otp: yup.string().required("OTP is required"),
});

const Otp = ({ navigateToAuth }: AuthProps): JSX.Element => {
  const [error, setError] = useState("");
  const location = useLocation();
  const search = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const formik = useFormik<VerifyOtpInputData>({
    initialValues: {
      otp: "",
    },
    validationSchema,
    onSubmit: async values => {
      console.log("yolo");
      values.otp = parseInt(String(values.otp));
      await submit({ otp: values.otp });
    },
    onReset: () => {},
  });

  const submit = async (credentials: VerifyOtpInput) => {
    try {
      console.log(search.get("action"));
      if (search.get("action") === "verify") {
        const { data: res } = await API_CALL.verifyOtp({ ...credentials, transient_key: String(search.get("transient_key")) });
        navigateToAuth({ page: "login" });
        notify("success", res.data.message);
      }
      if (search.get("action") === "forgot") {
        const { data: res } = await API_CALL.verifyResetOtp({ ...credentials, email: String(search.get("email")) });
        navigateToAuth({ page: "reset-password", email: String(search.get("email")), reset_token: res.data.reset_token });
        notify("success", res.data.message);
      }
    } catch (err: any) {
      const msg = err.response.data.message;
      setError(String(msg));
    }
  };

  return (
    <Fragment>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "grid", gap: 2 }}>
          {(Object.keys(formik.values) as InputArr[]).map((key, idx) => (
            <TextField
              key={idx}
              name={key}
              type="text"
              label={capitalize(key)}
              value={formik.values[key]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[key] && Boolean(formik.errors[key])}
              helperText={formik.touched[key] && formik.errors[key]}
            />
          ))}

          {error && <Alert severity="error">{error}</Alert>}

          <Button type="submit" variant="contained" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? "Verifying" : "Verify"}
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Otp;
