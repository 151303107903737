import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography, useTheme } from "@mui/material";
import * as path from "../../router/path";
import * as title from "../../router/title";
import PeopleIcon from "@mui/icons-material/People";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import SensorsIcon from "@mui/icons-material/Sensors";
import HistoryIcon from "@mui/icons-material/History";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SettingsIcon from "@mui/icons-material/Settings";
import useUserStore from "../../store/userStore";

interface Props {
  drawerWidth: number;
  mobileOpen: boolean;
  handleDrawerTransitionEnd: () => void;
  handleDrawerClose: () => void;
}

const list = [
  {
    title: title.base,
    path: path.base,
    icon: DashboardIcon,
  },
  {
    title: title.users,
    path: path.users,
    icon: PeopleIcon,
    authorized: true,
  },
  {
    title: title.live,
    path: path.live,
    icon: PlayArrowIcon,
  },
  {
    title: title.history,
    path: path.history,
    icon: HistoryIcon,
  },
  {
    title: title.sensors,
    path: path.sensors,
    icon: SensorsIcon,
  },
  {
    title: title.shop,
    path: path.shop,
    icon: ShoppingBasketIcon,
  },
  {
    title: title.setting,
    path: path.setting,
    icon: SettingsIcon,
  },
];

function Sidebar({ drawerWidth, mobileOpen, handleDrawerTransitionEnd, handleDrawerClose }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUserStore();
  const theme = useTheme();

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="h5" component="h1" fontWeight={600} color={theme.palette.grey[700]} textTransform="uppercase">
          <Typography variant="h5" component="span" fontWeight={600} color={theme.palette.primary.main} display="inline">
            Hex
          </Typography>{" "}
          Cloud
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {list
          .filter(val => (val.authorized ? user?.role === "administrator" : true))
          .map((item, idx) => {
            const isActive = item.path === location.pathname;
            return (
              <ListItem key={idx} disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate(item.path);
                  }}
                  sx={{ color: isActive ? theme.palette.primary.main : "" }}>
                  <ListItemIcon>{<item.icon color={isActive ? "primary" : undefined} />}</ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
              </ListItem>
            );
          })}
      </List>
      <Divider />
    </div>
  );

  return (
    <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}>
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        open>
        {drawer}
      </Drawer>
    </Box>
  );
}

export default Sidebar;
