import { Alert, Box, Button, TextField } from "@mui/material";
import * as yup from "yup";
import { type UpdateProfileRequestBody } from "../../types/auth";
import { useFormik } from "formik";
import API_CALL from "../../services";
import useUserStore from "../../store/userStore";
import notify from "../../utils/notify";
import { useState } from "react";
import { capitalize } from "lodash";

type InputArr = keyof UpdateProfileRequestBody;

const validationSchema = yup.object<UpdateProfileRequestBody>({
  first_name: yup.string(),
  last_name: yup.string(),
  bio: yup.string(),
});

export default function EditProfile() {
  const { user, setUser } = useUserStore();
  const [error, setError] = useState("");
  const formik = useFormik<UpdateProfileRequestBody>({
    initialValues: {
      first_name: user?.first_name ?? "",
      last_name: user?.last_name ?? "",
      bio: user?.bio ?? "",
    },
    validationSchema,
    onSubmit: async values => {
      await submit(values);
    },
    onReset: () => {},
  });

  const submit = async (credentials: UpdateProfileRequestBody) => {
    try {
      const { data: res } = await API_CALL.updateProfile(credentials);
      setUser(res.data);
      notify("success", "Your profile was updated");
    } catch (err: any) {
      const msg = err.response.data.message;
      setError(String(msg));
    }
  };

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Box sx={{ display: "grid", gap: 2 }}>
        {(Object.keys(formik.values) as InputArr[]).map((key, idx) => (
          <TextField
            key={idx}
            name={key}
            type="text"
            label={capitalize(key.split("_").join(" "))}
            value={formik.values[key]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[key] && Boolean(formik.errors[key])}
            helperText={formik.touched[key] && formik.errors[key]}
          />
        ))}

        {error && <Alert severity="error">{error}</Alert>}

        <Button type="submit" variant="contained" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? "Updating" : "Update"}
        </Button>
      </Box>
    </Box>
  );
}
