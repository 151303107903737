import { Fragment, useMemo, useState } from "react";
import { TextField, Button, Box, Alert } from "@mui/material";
import API_CALL from "../../services";
import * as yup from "yup";
import { type AuthProps } from ".";
import { useFormik } from "formik";
import { type ResetPasswordRequestBody } from "../../types/auth";
import { capitalize } from "lodash";
import { useLocation } from "react-router-dom";
import notify from "../../utils/notify";

type ResetPasswordInput = Omit<ResetPasswordRequestBody, "email" | "reset_token"> & { confirm_password: string };
type InputArr = keyof ResetPasswordInput;

const validationSchema = yup.object<ResetPasswordRequestBody>({
  password: yup.string().required("Password is required").min(8, "Password should be of minimum 8 characters length"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
});

const ForgotPassword = ({ navigateToAuth }: AuthProps): JSX.Element => {
  const [error, setError] = useState("");
  const location = useLocation();
  const search = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const formik = useFormik<ResetPasswordInput>({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema,
    onSubmit: async values => {
      await submit(values);
    },
    onReset: () => {},
  });

  const submit = async (credentials: ResetPasswordInput) => {
    try {
      const { data: res } = await API_CALL.resetPassword({ email: String(search.get("email")), reset_token: String(search.get("reset_token")), password: credentials.password });
      notify("success", res.data.message);
      navigateToAuth({ page: "login" });
    } catch (err: any) {
      const msg = err.response.data.message;
      setError(String(msg));
    }
  };

  return (
    <Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "grid", gap: 2 }}>
          {(Object.keys(formik.values) as InputArr[]).map((key, idx) => (
            <TextField
              key={idx}
              name={key}
              type="password"
              label={capitalize(key)}
              value={formik.values[key]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[key] && Boolean(formik.errors[key])}
              helperText={formik.touched[key] && formik.errors[key]}
            />
          ))}

          {error && <Alert severity="error">{error}</Alert>}

          <Button type="submit" variant="contained" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? "Resetting" : "Reset"}
          </Button>
        </Box>
      </form>
    </Fragment>
  );
};

export default ForgotPassword;
