import { Box, Tab, Tabs as MuiTabs } from "@mui/material";
import React, { Fragment, type ReactNode, useState } from "react";

interface Props {
  title: string;
  tabLabels: ReactNode[];
  children?: React.ReactNode;
  onClick: (value: number) => void;
}

function Tabs({ title, tabLabels, children, onClick }: Props) {
  const [value, setValue] = useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (onClick) onClick(newValue);
  };

  return (
    <Fragment>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <MuiTabs value={value} onChange={handleChange} aria-label={title}>
          {tabLabels.map((val, idx) => (
            <Tab key={idx} label={val} {...a11yProps(idx)} sx={{ fontSize: 12 }} />
          ))}
        </MuiTabs>
      </Box>
      {React.Children.map(children, (child, idx) => (
        <CustomTabPanel key={idx} value={value} index={idx}>
          {child}
        </CustomTabPanel>
      ))}
    </Fragment>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default Tabs;
