import {
  type GetUsersResponseBody,
  type ForgotPasswordRequestBody,
  type ForgotPasswordResponseBody,
  type LoginRequestBody,
  type LoginResponseBody,
  type RegisterRequestBody,
  type RegisterResponseBody,
  type ResetPasswordRequestBody,
  type ResetPasswordResponseBody,
  type UpdatePasswordRequestBody,
  type UpdatePasswordResponseBody,
  type UpdateProfileRequestBody,
  type UpdateProfileResponseBody,
  type ValidateResponseBody,
  type VerifyOtpRequestBody,
  type VerifyOtpResponseBody,
  type VerifyResetOtpRequestBody,
  type VerifyResetOtpResponseBody,
} from "../types/auth";
import jsonApi from "./jsonInstance";

const API_CALL = {
  // auth - public
  login: async (data: LoginRequestBody): LoginResponseBody => await jsonApi.post("/auth/login", { ...data, agent: "web" }),
  register: async (data: RegisterRequestBody): RegisterResponseBody => await jsonApi.post("/auth/register", data),
  verifyOtp: async (data: VerifyOtpRequestBody): VerifyOtpResponseBody => await jsonApi.post("/auth/verify-otp", data),
  forgotPassword: async (data: ForgotPasswordRequestBody): ForgotPasswordResponseBody => await jsonApi.post("/auth/forgot-password", data),
  verifyResetOtp: async (data: VerifyResetOtpRequestBody): VerifyResetOtpResponseBody => await jsonApi.post("/auth/verify-reset-otp", data),
  resetPassword: async (data: ResetPasswordRequestBody): ResetPasswordResponseBody => await jsonApi.post("/auth/reset-password", data),
  // auth - private
  giveConsent: async (consentGiven: boolean) => await jsonApi.post("/auth/give-consent", { consentGiven }),
  validate: async (): ValidateResponseBody => await jsonApi.get("/auth/validate"),
  logout: async () => await jsonApi.post("/auth/logout"),

  // users
  updateProfile: async (data: UpdateProfileRequestBody): UpdateProfileResponseBody => await jsonApi.post("/users/update-profile", data),
  updatePassword: async (data: UpdatePasswordRequestBody): UpdatePasswordResponseBody => await jsonApi.post("/users/update-password", data),
  getUsers: async (type: string, page: number): GetUsersResponseBody => await jsonApi.get(`/users?type=${type}&page=${page}`),
};

export default API_CALL;
