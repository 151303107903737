import { Fragment, useState } from "react";
import { TextField, Button, Box, Typography, Alert, Modal, Fade, Backdrop } from "@mui/material";
import API_CALL from "../../services";
import * as yup from "yup";
import useUserStore from "../../store/userStore";
import useAuthStore from "../../store/authStore";
import { type AuthProps } from ".";
import { useFormik } from "formik";
import { type LoginRequestBody } from "../../types/auth";
import { capitalize } from "lodash";

type InputArr = keyof LoginRequestBody;

const listOne = [
  {
    title: "Data Logging",
    content: "Our app will automatically log various sensor data, including temperature, humidity, EMF readings, and more from the sensors you use",
  },
  {
    title: "GPS Coordinates",
    content: "Along with the sensor data, we will collect and store your current GPS coordinates.",
  },
  {
    title: "Public Disclosure",
    content: "The collected data, including GPS coordinates, will be publicly accessible on our website.",
  },
];

const listTwo = [
  {
    content: "Our app will automatically log various sensor data, including temperature, humidity, EMF readings, and more from the sensors you use",
  },
  {
    content: "Along with the sensor data, we will collect and store your current GPS coordinates.",
  },
  {
    content: "The collected data, including GPS coordinates, will be publicly accessible on our website.",
  },
];

const listThree = [
  {
    content: "Your personal information (name, email, etc.) will be kept confidential and will not be shared publicly.",
  },
  {
    content: "Only sensor data and GPS coordinates will be made public.",
  },
];

const validationSchema = yup.object({
  username: yup.string().required("Email is required"),
  password: yup.string().min(8, "Password should be of minimum 8 characters length").required("Password is required"),
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  outline: "none",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
  "& :is(p, ul):not(:last-child)": {
    mb: 3,
  },
  h4: {
    mb: 2,
    color: "#777",
  },
  "& :is(h2, div)": {
    mb: 2,
  },
  "& :is(h4, h2)": {
    fontWeight: 600,
  },
  "& :is(p:last-child, a)": {
    fontSize: 13,
  },
  ul: {
    pl: 2.5,
  },
};

const Login = ({ navigateToAuth }: AuthProps) => {
  const { setUser } = useUserStore();
  const { setAuth } = useAuthStore();
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik<LoginRequestBody>({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema,
    onSubmit: async values => {
      await submit(values);
    },
    onReset: () => {},
  });

  const submit = async (credentials: LoginRequestBody) => {
    try {
      const { data: res } = await API_CALL.login(credentials);
      setUser(res.data);
      if (res.data.consent_given) setAuth(true);
      else setOpen(true);
    } catch (err: any) {
      const msg = err.response.data.message;
      setError(String(msg));
    }
  };

  const consent = async () => {
    try {
      setLoading(true);
      await API_CALL.giveConsent(true);
      setOpen(prev => !prev);
      setAuth(true);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.log(err.response.data);
    }
  };

  return (
    <Fragment>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "grid", gap: 2 }}>
          {(Object.keys(formik.values) as InputArr[]).map((key, idx) => (
            <TextField
              key={idx}
              name={key}
              type={key === "username" ? "text" : "password"}
              label={capitalize(key)}
              value={formik.values[key]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched[key] && Boolean(formik.errors[key])}
              helperText={formik.touched[key] && formik.errors[key]}
            />
          ))}

          {error && <Alert severity="error">{error}</Alert>}

          <Button type="submit" variant="contained" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? "Logging In" : "Login"}
          </Button>
        </Box>
      </Box>

      <Button
        onClick={() => {
          navigateToAuth({ page: "forgot-password" });
        }}
        sx={{
          ml: "auto",
          display: "block",
          width: "fit-content",
          mb: -1,
          fontSize: "14px",
          textTransform: "none",
          "&:hover": {
            cursor: "pointer",
            textDecoration: "underline",
            backgroundColor: "inherit", // To prevent background change on hover
          },
        }}>
        Forgot your password?
      </Button>

      <Box
        sx={{
          mt: 6,
          mb: -2,
          p: 2,
          textAlign: "center",
          width: "100%",
        }}>
        <Typography sx={{ fontSize: "14px" }}>
          Don&apos;t have an account?{" "}
          <Button
            onClick={() => {
              navigateToAuth({ page: "register" });
            }}
            sx={{
              fontSize: "14px",
              textTransform: "none",
              "&:hover": {
                textDecoration: "underline",
                backgroundColor: "inherit", // To prevent background change on hover
              },
            }}>
            Sign Up
          </Button>
        </Typography>
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h4" component="h2">
              Welcome to Hex Cloud!
            </Typography>

            <Typography variant="body1" component="p">
              Before you start using the platform, we need to inform you about our data collection practices and obtain your consent.
            </Typography>

            <Typography variant="h6" component="h4">
              Data Collection and Usage:
            </Typography>

            <Typography variant="body1" component="ul">
              {listOne.map((val, idx) => (
                <Typography key={idx} component="li">
                  <Typography component="b">{val.title}</Typography>
                  {val.content}
                </Typography>
              ))}
            </Typography>

            <Typography variant="h6" component="h4">
              Purpose of Data Collection:
            </Typography>

            <Typography variant="body1" component="ul">
              {listTwo.map((val, idx) => (
                <Typography key={idx} component="li">
                  {val.content}
                </Typography>
              ))}
            </Typography>

            <Typography variant="h6" component="h4">
              Data Privacy and Security:
            </Typography>

            <Typography variant="body1" component="ul">
              {listThree.map((val, idx) => (
                <Typography key={idx} component="li">
                  {val.content}
                </Typography>
              ))}
            </Typography>

            <Typography variant="body1" component="p">
              By clicking &quot;I Agree,&quot; you consent to the collection, storage, and public disclosure of your logged data and GPS coordinates as described above.
            </Typography>

            <Typography variant="body1" component="p">
              If you do not agree, you will not be able to use the Hex Cloud system
            </Typography>

            <Box display="flex" gap={3}>
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => {
                  void consent();
                }}>
                I Agree
              </Button>

              <Button variant="contained" disabled={loading} onClick={handleClose}>
                Cancel
              </Button>
            </Box>

            <Typography variant="body2" component="p">
              For more details, please refer to our{" "}
              <Typography variant="body1" component="a" href="#" color="primary">
                Privacy Policy
              </Typography>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </Fragment>
  );
};

export default Login;
