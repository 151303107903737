import { create } from "zustand";
import { type GetUsers, type User } from "../types/auth";

export type UserType = "active" | "inactive";

export type UserPagination = GetUsers["data"]["pagination"];
interface UserStore {
  active: Record<number, User[]>;
  inactive: Record<number, User[]>;
  pushUsers: (user: User[], type: UserType, page: number) => void;

  userNavigation: {
    type: UserType;
    pagination: Record<UserType, UserPagination>;
  };
  setUserNavigationType: (type: UserType) => void;
  setUserNavigation: (type: UserType, navigation: UserPagination) => void;
}

const defaultPagination = {
  per_page: 100,
  current_page: 1,
  total_pages: 1,
  total_users: 0,
};

const useUsersStore = create<UserStore>(set => ({
  active: {},
  inactive: {},
  pushUsers: (users: User[], type: UserType, page: number) => {
    set(state => ({ [type]: { ...state.active, ...state.inactive, [page]: users } }));
  },

  userNavigation: {
    type: "active",
    pagination: {
      active: defaultPagination,
      inactive: defaultPagination,
    },
  },
  setUserNavigationType: (type: UserType) => {
    set(state => ({ ...state, userNavigation: { ...state.userNavigation, type } }));
  },
  setUserNavigation: (type: UserType, navigation: UserPagination) => {
    set(state => ({ ...state, userNavigation: { ...state.userNavigation, pagination: { ...state.userNavigation.pagination, [type]: navigation } } }));
  },
}));

export default useUsersStore;
